import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import HeaderBanner from "../components/headerbanner"
import ListYourSpaces from "../components/ListYourSpaces"
import SEOHeader from "../components/seo-header"
import SocialImage from "../img/socialimage/listyourspace.jpg"
const ListYourSpace = () => {
  return (
    <div>
      <SEOHeader
        title="GoFloaters | How Are We Different From Others"
        description="Low rental cost, No Contracts, No Lock-in Periods, Cool working environment & Community Partner Benefits"
        socialURL="https://assets.gofloaters.com/socialimage/listyourspace.jpg"
        pinterest="true"
      ></SEOHeader>
      <Layout>
        <div className="header-banner makemoney">
          <h1 style={{ color: "#fff" }}>
            Make Money from your Fully-Furnished Unoccupied Spaces
          </h1>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h3 style={{ color: "#4f9fc8" }}>
                Coworking Space or Business Center owners
              </h3>
              <p>
                We can help you find short/long term clients by listing your
                property on our platform. All our properties are vetted, and
                relevant amenities are highlighted to ensure customers know all
                your offerings. We also frequently collaborate with Coworking
                Spaces to conduct sponsored events that can bring visibility.
              </p>
            </div>

            <div className="col-md-12 text-center">
              <br></br>
              <h3 style={{ color: "#4f9fc8" }}>
                Business owners with spare office space
              </h3>
              <p className="text-center">
                We can help you hire out your spare seating and make your
                business some money in the process. List your underutilized
                spaces on GoFloaters, help your business generate extra income
                on the side.
              </p>
            </div>

            {/* <div className="col-md-12 text-center">
              <br></br>
              <h3 style={{ color: "#4f9fc8" }}>
                Owners of any other kind of space
              </h3>

              <p className="text-center">
                During closed hours or off-peak times, it can double as a
                meeting spot or an event space or even a place to sit and work.
                If you have spare space of any kind
                <strong> we can help you make some money off it</strong>!
              </p>
            </div> */}
          </div>

          <div className="row">
            <div className="col-md-12 text-center">
              <br></br>
              <h2 style={{ color: "#4f9fc8" }}>Interested in knowing more?</h2>
              <p>
                Please fill and submit the form below and we will be in touch.
              </p>
            </div>

            <div className="col-md-12">
              {/* <ListYourSpaces></ListYourSpaces> */}
              <iframe
                height="800px"
                width="100%"
                frameborder="0"
                allowTransparency="true"
                scrolling="auto"
                src="https://creatorapp.zohopublic.com/gofloaters/crm/form-embed/Supply/e2z37X740bAtDQx1pd2Ob41pS3w4k7E5YA3j5BFatxvxt9YX875Dp8fHzXYQhxVhN71hru1XbaVC7vOde2uKTbJsEmgqfrJXPN3y"
              ></iframe>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center">
              <br></br>
              <h2 style={{ color: "#4f9fc8" }}>
                Join our India's Largest Network of Top Flex Space Providers and
                Reach Thousands of Customers
              </h2>
              <p>
                Experience the ultimate in workplace flexibility with our global
                network of exceptional workspaces. Our innovative app brings
                together hundreds of top flex space providers, offering you
                thousands of locations to choose from. Discover the perfect
                workspace that suits your needs, all in one beautifully designed
                platform. Join us and unlock a world of possibilities with our
                esteemed partners.
              </p>
            </div>
            {/* Supply Testimonail Start */}
            <div className="col-md-6">
              <br></br>
              <div className="supplyTestimonail">
                <img
                  src="https://assets.gofloaters.com/partner/gofloater-amigo.jpg"
                  style={{ width: "80%" }}
                />
                <br />
                <br />
                <p className="supplyTestimonailContent">
                  "We have been associated with GoFloaters for sometime now. We
                  have found them to be the most professional aggregator with
                  quick turnarounds, consistent updates, invoice clearance on
                  time. Moreover the quality of customers is exceptional. I
                  would also like to add, a lot of time the the team at
                  GoFloaters, the ones we work with, are always professional,
                  courteous and kind. I am grateful for this collaboration
                  because it makes so much business sense. Look forward to
                  more."
                </p>
                <p className="supplyTestimonailcustomer">
                  <span>KanwarAnand,</span>
                  <br />
                  Founder, Amigo Coworking,
                  <br /> Noida - New Delhi
                </p>
              </div>
            </div>
            {/* Supply Testimonail End */}
            {/* Supply Testimonail Start */}
            <div className="col-md-6">
              <br></br>
              <div className="supplyTestimonail">
                <img
                  src="https://assets.gofloaters.com/partner/gofloater-dot.jpg"
                  style={{ width: "80%" }}
                />
                <br />
                <br />
                <p className="supplyTestimonailContent">
                  “We have been associated with GoFloaters since the opening of
                  our first branch. In the last 3+ years of our association,
                  this partnership has helped us continuously achieve more
                  business along with quality of customers. The team at
                  GoFloaters has strong values & professional ethics, this is
                  something that we value more than the business support that
                  they have provided. We are happy with this continued
                  partnership with Shyam & his team at GoFloaters. Highly
                  recommend this workspace platform”
                </p>
                <p className="supplyTestimonailcustomer">
                  <span>Sudarshankrishna B,</span>
                  <br />
                  Business Operations Manager
                  <br /> Disruptors of Tomorrow Co-Working Chennai
                </p>
              </div>
            </div>
            {/* Supply Testimonail End */}

            <div className="col-md-3"></div>
            {/* Supply Testimonail Start */}
            <div className="col-md-6">
              <br></br>
              <div className="supplyTestimonail">
                <img
                  src="https://assets.gofloaters.com/partner/gofloaters-coworking.jpg"
                  style={{ width: "80%" }}
                />
                <br />
                <br />
                <p className="supplyTestimonailContent">
                  The results have been great. It’s been a great association
                  with “GoFloaters'' value for time & ROI
                </p>
                <p className="supplyTestimonailcustomer">
                  <span>V V Swaroop Director</span>
                  <br />
                  Operations The Coworking Space <br /> Andhra Pradesh |
                  Karnataka | Tamilnadu | Telangana | Pondicherry
                </p>
              </div>
            </div>
            {/* Supply Testimonail End */}
            <div className="col-md-12">
              {" "}
              <br></br> <br></br>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default ListYourSpace
